.select-container {
  display: flex;
  width: 100%;
  margin: 0.5rem 0 1.5rem;
}

div#select > button {
  width: "auto";
  border: none;
  background: none;
  padding: 1px;
  margin: 5px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
}
div#select > button:focus {
  border: 0;
  outline: none;
}
.unselected {
  width: 50%;
  color: rgba(0, 0, 0, 0.38);
  border-bottom: none;
}

div#select > button.active {
  width: 50%;
  margin: 0;
  color: black;
  border-bottom: 2px solid rgb(40, 167, 69) !important;
}
