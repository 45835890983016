#commodity-table {
  font-family: sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#commodity-table td,
#commodity-table th {
  border: 1px solid #ddd;
  padding: 10px;
  border-collapse: collapse;
}

td#inner {
  border-bottom: 1px solid #ddd;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  padding: 2px;
}
#commodity-table tr:nth-child(odd) {
  background-color: rgb(247, 240, 240);
}
#commodity-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: rgb(14, 126, 14);
  color: #ffff;
}

#commodity-table .w-10 {
  min-width: 200px;
}
