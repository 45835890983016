.product-card {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 390.3px;
  cursor: pointer;
  transition: transform 0.2s;
  /* width: 347.7px; */
  /* margin: 5px; */
}
.product-card:hover {
  transform: scale(1.025);
}
/* #product.product-image {
  height: 223.5px;
  width: 347px;
} */
img.product-image {
  width: 347px;
  height: 223.5px;
  object-fit: cover;
  margin: 0;
}
.short-des {
  width: 100%;
  height: 130.54px;
  background-color: #f3f2f0;
  color: #2b252c;
  /* position: absolute; */
  padding: 17px;
  /* margin-top: 259px; */
}

p#product-name {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
}
p#description {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}
.status {
  display: flex;
  justify-content: space-between;
  width: 300.7px;
}

button#product {
  color: white;
  border: 0;
  text-transform: uppercase;
  padding: 3px;

  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
}
button.available {
  background: #59ff38;
}
button.not-available {
  background: crimson;
}
