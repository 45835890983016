.transaction-card {
  width: 100%;
  min-width: 1000px;
  padding: 0.5em;
  border-bottom: 1px solid silver;
  margin: 0.5rem 0 0.5em;
  
}

.transaction-card:hover {
  box-shadow: 0px 15px 30px rgba(50, 2, 52, 0.15);
}
.details {
  display: inline-flex;
  min-width: 100%;
  min-height: 82.02px;
  margin: auto;
}

.narration {
  width: 50%;
  margin: auto;
}
.balances {
  width: 50%;
  margin: auto;
}
.loan{
    border-right: 1px solid black;
}