.pdf-container{
    width: 100%;
    background-color: #e4e4e4;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .preview-container{
    height:200px;
  }

  .main-container{
    height:800px;
  }