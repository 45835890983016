.title {
  margin-bottom: 5vh;
}
.empty_message {
  position: absolute;
  top: 10;
  left: 50;
  right: 50;
  margin: 3em;
}

div#cart.card {
  margin: auto;
  /* max-width: 950px; */
  width: 100%;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 1rem;
  vertical-align: middle;
  border: transparent;
  font-family: sans-serif;
  font-size: 0.95rem;
  font-weight: bold;
}

@media (max-width: 767px) {
  div#cart.card {
    margin: 3vh auto;
  }
}

.cart {
  background-color: #fff;
  padding: 4vh 5vh;
  border-bottom-left-radius: 1rem;
  border-top-left-radius: 1rem;
}

@media (max-width: 767px) {
  .cart {
    padding: 4vh;
    border-bottom-left-radius: unset;
    border-top-right-radius: 1rem;
  }
}

.summary {
  background-color: rgb(181 207 173);
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  padding: 4vh;
  color: rgb(8 7 7);
}

@media (max-width: 767px) {
  .summary {
    border-top-right-radius: unset;
    border-bottom-left-radius: 1rem;
  }
}

#cart .summary .col-2 {
  padding: 0;
}

#cart .summary .col-10 {
  padding: 0;
}

#cart .row {
  margin: 0;
}

#cart .title b {
  font-size: 1.5rem;
}

#cart .main {
  margin: 0;
  padding: 2vh 0;
  width: 100%;
}

#cart .col-2,
#cart .col {
  padding: 0 1vh;
}

#cart a {
  padding: 0 1vh;
}

#cart .close {
  margin-left: auto;
  font-size: 0.7rem;
}

#cart img {
  width: 3.5rem;
}

#cart .back-to-shop {
  margin-top: 4.5rem;
}

#cart h5 {
  margin-top: 4vh;
}

#cart hr {
  margin-top: 1.25rem;
}

div#cart > form {
  padding: 2vh 0;
}

#cart .check-out-btn {
  background-color: #000;
  border-color: #000;
  color: white;
  width: 100%;
  font-size: 0.7rem;
  margin-top: 4vh;
  padding: 1vh;
  border-radius: 0;
}

#cart .btn:focus {
  box-shadow: none;
  outline: none;
  box-shadow: none;
  color: white;
  -webkit-box-shadow: none;
  -webkit-user-select: none;
  transition: none;
}

#cart .btn:hover {
  color: white;
}

#cart a {
  color: black;
}

#cart a:hover {
  color: black;
  text-decoration: none;
}

#code {
  background-image: linear-gradient(
      to left,
      rgba(255, 255, 255, 0.253),
      rgba(255, 255, 255, 0.185)
    ),
    url("https://img.icons8.com/small/16/000000/long-arrow-right.png");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: center;
}
