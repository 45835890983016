@media screen and (max-width: 400px) {
	#features {
		padding: 20px;
		width: 100%;
	}
	#about,
	#services,
	#testimonials,
	#team,
	#contact,
	#footer {
		width: 100%;
	}

	#portfolio {
		width: 100%;
	}
}

#loan-contract {
	font-size: 1.4rem;
}
#loan-contract p {
	font-size: 1.21rem;
	font-weight: 400;
	margin-bottom: 0.2rem;
}
#nb p {
	font-size: 0.8rem;
}
#loan-contract table {
	font-size: 1.1rem;
	margin: auto;
}

.main-sidebar {
	height: 100vh;
}

.card .table,
.card .table.style--two {
	box-shadow: none;
}

.table.white-space-nowrap {
	white-space: nowrap;
}

.table.style--two {
	background-color: rgb(247, 243, 243);
	color: #000000;

	border-radius: 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
	overflow: hidden;
	box-shadow: 0 5px 5px 0 rgba(204, 163, 84, 0.25);
}

.table.style--two thead {
	background-color: white;
}

.table.style--two thead tr th {
	border-top: none;
	border-bottom: none;
	color: #000000;
	text-align: center;
	padding: 10px 20px;
}

.table.style--two thead tr th:first-child {
	text-align: left;
}

.table.style--two thead tr th:last-child {
	text-align: right;
}

.table.style--two tr th,
.table.style--two tr td {
	font-size: 14px;
	border-top-color: rgba(204, 163, 84, 0.25);
}

.table.style--two tbody tr td {
	text-align: center;
	color: #080808;
	padding: 15px 20px;
	vertical-align: middle;
	font-size: 15px;
}

.table.style--two tbody tr td:first-child {
	text-align: left;
}

.table.style--two tbody tr td:last-child {
	text-align: right;
}

.table.style--two tbody tr:first-child td {
	border-top: none;
}

.table .user {
	display: flex;
	align-items: center;
}

.table .user .thumb {
	width: 45px;
	height: 45px;
	border: 2px solid #ffffff;
	overflow: hidden;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	-o-border-radius: 50%;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
	margin-right: 10px;
}

.table .user .thumb img {
	width: inherit;
	height: inherit;
}

.table-striped tbody tr:nth-of-type(odd) {
	background-color: #6e41ff0f;
}

.badge {
	font-weight: 400;
	padding: 4px 10px;
}

.icon-btn {
	width: 30px;
	height: 30px;
	display: inline-block;
	text-align: center;
	line-height: 30px;
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
	-o-border-radius: 3px;
	box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25);
	font-size: 14px;
}

[data-label] {
	position: relative;
}

[data-label]::before {
	position: absolute;
	content: attr(data-label);
	font-weight: 700;
	color: #ffffff;
	top: 0;
	left: 0;
	padding: 13px 15px;
	display: none;
	font-size: 12px;
}

@media (max-width: 1199px) {
	.table-responsive--lg thead {
		display: none;
	}

	.table-responsive--lg tbody tr:nth-child(odd) {
		background-color: #232323;
	}

	.table-responsive--lg tr th,
	.table-responsive--lg tr td {
		display: block;
		padding-left: 45% !important;
		text-align: right !important;
		border-top: 1px solid #3e3e3e !important;
	}

	.table-responsive--lg .user {
		justify-content: flex-end;
	}

	.table-responsive--lg [data-label]::before {
		display: block;
	}
}

@media (max-width: 991px) {
	.table-responsive--md thead {
		display: none;
	}

	.table-responsive--md tbody tr:nth-child(odd) {
		background-color: #232323;
	}

	.table-responsive--md tr th,
	.table-responsive--md tr td {
		display: block;
		padding-left: 45% !important;
		text-align: right !important;
		border-top: 1px solid #3e3e3e !important;
	}

	.table-responsive--md .user {
		justify-content: flex-end;
	}

	.table-responsive--md [data-label]::before {
		display: block;
	}
}

@media (max-width: 767px) {
	.table-responsive--sm thead {
		display: none;
	}

	.table-responsive--sm tbody tr:nth-child(odd) {
		background-color: #bbadad;
	}

	.table-responsive--sm tr th,
	.table-responsive--sm tr td {
		display: block;
		padding-left: 45% !important;
		text-align: right !important;
		border-top: 1px solid #3e3e3e !important;
	}

	.table-responsive--sm .user {
		justify-content: flex-end;
	}

	.table-responsive--sm [data-label]::before {
		display: block;
	}
}

@media (max-width: 575px) {
	.table-responsive--xs thead {
		display: none;
	}

	.table-responsive--xs tbody tr:nth-child(odd) {
		background-color: #232323;
	}

	.table-responsive--xs tr th,
	.table-responsive--xs tr td {
		display: block;
		padding-left: 45% !important;
		text-align: right !important;
		border-top: 1px solid #3e3e3e !important;
	}

	.table-responsive--xs .user {
		justify-content: flex-end;
	}

	.table-responsive--xs [data-label]::before {
		display: block;
	}
}
.ni-info:before {
	content: "";
}
.nk-alert-action {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	background: #fff;
	border: 1px solid #6cbbf7;
	border-radius: 4px;
	padding: 0.75rem 1.125rem;
}
ol,
ul {
	list-style: none;
	margin: 0;
	padding: 0;
}
.gx-4 > li,
.gx-4 > div {
	padding-left: 0.75rem !important;
	padding-right: 0.75rem !important;
}
.nk-alert-links {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	font-size: 13px;
	font-weight: 500;
}
.gx-4 {
	margin-left: -0.75rem !important;
	margin-right: -0.75rem !important;
}

.nk-wgacc-group {
	display: flex;
	align-items: flex-end;
	flex-wrap: wrap;
}
.gx-4 {
	margin-left: -0.75rem !important;
	margin-right: -0.75rem !important;
}
.nk-wgacc-sub {
	font-size: 0.875rem;
	color: #000000;
	margin-top: 0.125rem;
}
@media (min-width: 992px) {
	.flex-lg-nowrap {
		flex-wrap: nowrap !important;
	}
}
@media (min-width: 992px) {
	.nk-alert-nav {
		width: auto;
		margin-left: 0;
	}
}
.nk-alert-nav {
	width: 100%;
	margin-left: 1.75rem;
}
@media (max-width: 1199px) {
	*[class*="table-responsive--"].data-label--none tr th,
	*[class*="table-responsive--"].data-label--none tr td {
		padding-left: 0.75rem;
	}
}

.sigCanvas {
	border: 1px solid black;
}

.seal-2 {
	position: absolute;
	top: 80%;
	left: 50%;
	transform: translate(-50%, -50%);
	opacity: 0.3;
	pointer-events: none;
	z-index: 100;
}

.watermark-2 {
	max-width: 100%;
	width: 400px;
	height: auto;
}
